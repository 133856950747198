export const selectOrderReviewsSlice = (state) => state?.orderReviews

export const selectVerosReviews = (state) => state?.orderReviews?.verosReviews

export const selectVerosReviewData = (state) => state?.orderReviews?.verosReviewData

export const selectSubmissionVeros = (state, submissionVersion) => {
  const verosReviews = selectVerosReviews(state)
  if (!verosReviews || !Array.isArray(verosReviews)) {
    return undefined
  }

  return verosReviews.find((review) => review.metadata?.submission_version === submissionVersion)
}

export const selectVerosData = (state, score_id) => {
  const verosReviews = selectVerosReviewData(state)

  return verosReviews[score_id] || null
}
