import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import NumberInput from '../../common/forms/inputs/NumberInput'
import SelectInput from '../../common/forms/inputs/SelectInput'
import TextInput from '../../common/forms/inputs/TextInput'
import CheckboxInput from '../../common/forms/inputs/CheckboxInput'
import { checkPermission } from '../../common/utils/helpers'
import ReducerStatuses from '../../common/constants/reducer_statuses'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap'
import { Spin } from 'antd'
import { connectResource } from '../../common/utils/resource'
import Loader from '../../common/layout/components/Loader'
import { connect } from 'react-redux'
import { selectMinimumOrder, selectPaymentAllowedStatus, selectPaymentAllowed } from '../../store/reducers/order/orderSelectors'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

class PaymentReceivedModal extends Component {
  getManualConsumerInfo = () => {
    if (this.props.payment && this.props.payment.user_type === 'manual') {
      const consumer = this.props.consumers.find(
        (cons) => cons.id === this.props.payment.consumer,
      )
      return {
        firstname: consumer.firstname,
        lastname: consumer.lastname,
        email: consumer.email,
      }
    } else {
      return {
        firstname: '',
        lastname: '',
        email: '',
      }
    }
  }

  getDefaultPaymentType = () => {
    const {
      borrower_payments_enabled,
      default_payment_type,
      user_payment_types,
    } = this.props

    if (default_payment_type) {
      return default_payment_type
    }

    let paymentType
    if (borrower_payments_enabled) {
      paymentType = 'stripe'
    } else {
      paymentType = user_payment_types[0].value
    }
    return paymentType
  }

  state = {
    user_type: this.props.payment ? this.props.payment.user_type : 'consumer',
    payment_type: this.props.payment ? this.props.payment.payment_type : this.getDefaultPaymentType(),
    manual_consumer_id:
      this.props.payment && this.props.payment.user_type === 'manual'
        ? this.props.payment.consumer
        : '',
    consumer:
      this.props.payment && this.props.payment.user_type === 'consumer'
        ? this.props.payment.consumer
        : null,
    amount: this.props.payment
      ? this.props.payment.amount / 100
      : this.props.total_due,
    up_to_amount:
      this.props.payment && this.props.payment.up_to_amount
        ? this.props.payment.up_to_amount / 100
        : null,
    paid: this.props.payment && this.props.payment.paid,
    selected_card: this.props.payment && this.props.payment.selected_card,
    display_max_amount_warning: false,
    submitted: false,
    canChargeAboveDue: false,
    // Always allow payment in Fee Escalation process
    paymentAllowed: this.props.paymentAllowed || this.props.is_fee_escalation_payment,
    showFeeRecalculating: false,
    ...this.getManualConsumerInfo(),
  }

  componentDidMount = () => {
    const { payment, analytics_track_component, analytics_data, paymentAllowedResource, total_due } = this.props
    // Do not check paymentAllowed if this is a fee escalation since appraiser has been assigned
    if (!this.props.is_fee_escalation_payment) {
      paymentAllowedResource
        .fetch()
        .then(({ payment_allowed, amount_due }) => {
          if (total_due && payment_allowed && amount_due && Number(amount_due.toFixed(2)) !== total_due) {
            this.setState({ showFeeRecalculating: true })
            setTimeout(() => this.props.refreshOrder(), 3000)
          }
        })
        // If we encounter an error, allow payment so we do not block orders from being paid for
        .catch(() => {
          this.setState({ paymentAllowed: true })
        })
    }
    // If the user is editing a payment for which the consumer is a manually added consumer, we need to
    // set the state so the modal shows the custom contact fields
    if (payment && payment.user_type === 'manual') {
      this.setState({
        consumer: 'adding_custom_contact',
      })
    }

    if (analytics_data) {
      // Segment tracking
      const { firstname, lastname, role, lender } = analytics_data
      analytics_track_component('Opened Modal', {
        user: `${firstname} ${lastname}`,
        role: role.name,
        lender: lender.name,
        component: this.constructor.name,
      })
    }

    this.setState((state) => {
      return { canChargeAboveDue: this.getCanChargeAboveDue(state.payment_type) }
    })
  }

  getCanChargeAboveDue = (payment_type) => payment_type === 'manual'

  isInvalidAmount = (payment_type, amount) => payment_type !== 'manual' && (parseFloat(amount) > 999999 || parseFloat(amount) <= 0)

  getAvailablePaymentTypes = () => {
    const { user_payment_types } = this.props

    return user_payment_types
  }

  hasBadPaymentType = () => {
    const { payment_type } = this.state

    const payment_values = this.getAvailablePaymentTypes().map((p) => p.value)

    if (payment_values.indexOf(payment_type) === -1) {
      return true
    }
    return false
  }

  toAmountInCents = (amount) => Math.round(parseFloat(amount) * 100)

  onEditSubmit = () => {
    const {
      consumer,
      amount,
      up_to_amount,
      manual_consumer_id,
      user_type,
      firstname,
      lastname,
      email,
      payment_type,
      paid,
      selected_card,
    } = this.state
    const {
      order_id,
      is_fee_escalation_payment,
      payment: { id },
      analytics_track_component,
      analytics_data,
    } = this.props

    let consumer_id
    if (user_type === 'consumer') {
      consumer_id = consumer
    } else {
      consumer_id = manual_consumer_id
    }

    return this.props.consumerPayment
      .put({
        consumer_id,
        amount: this.toAmountInCents(amount),
        up_to_amount: this.toAmountInCents(up_to_amount),
        order_id,
        payment_id: id,
        firstname,
        lastname,
        email,
        user_type,
        payment_type,
        is_fee_escalation_payment,
        paid,
        selected_card,
      })
      .then((_) => {
        this.props.onHide()
        if (analytics_data) {
          // Segment Tracking
          const { firstname, lastname, role, lender } = analytics_data
          analytics_track_component('Submitted An Edit', {
            user: `${firstname} ${lastname}`,
            role: role.name,
            lender: lender.name,
            component: this.constructor.name,
          })
        }
      })
  }

  onCreationSubmit = () => {
    const {
      consumer,
      amount,
      up_to_amount,
      user_type,
      firstname,
      lastname,
      email,
      payment_type,
      paid,
      selected_card,
    } = this.state
    const {
      order_id,
      is_fee_escalation_payment,
      pay_with_new_card,
      currentUserId,
      analytics_track_component,
      analytics_data,
    } = this.props

    let consumer_id
    if (user_type === 'consumer') {
      consumer_id = consumer
    }

    const selected_payment_type = payment_type

    if (pay_with_new_card) {
      return this.props.payForAppraisal
        .post({
          user_id: currentUserId,
          order_id: order_id,
          amount: this.toAmountInCents(amount),
          up_to_amount: this.toAmountInCents(up_to_amount),
          payment_type: 'lender',
          paid: false,
          is_fee_escalation_payment: true,
        })
        .then((link) => {
          window.open(link, '_blank')
        })
        .then((_) => {
          this.props.onHide()
          if (analytics_data) {
            // Segment Tracking
            const { firstname, lastname, role, lender } = analytics_data
            analytics_track_component('Submitted Create Payment', {
              user: `${firstname} ${lastname}`,
              role: role.name,
              lender: lender.name,
              component: this.constructor.name,
            })
          }
        })
    } else {
      return this.props.consumerPayment
        .post({
          consumer_id: consumer_id,
          amount: this.toAmountInCents(amount),
          up_to_amount: this.toAmountInCents(up_to_amount),
          order_id: order_id,
          firstname,
          lastname,
          email,
          user_type,
          payment_type: selected_payment_type,
          is_fee_escalation_payment,
          paid,
          selected_card,
        })
        .then((_) => {
          this.props.onHide()
          if (analytics_data) {
            // Segment Tracking
            const { firstname, lastname, role, lender } = analytics_data
            analytics_track_component('Submitted Create Payment', {
              user: `${firstname} ${lastname}`,
              role: role.name,
              lender: lender.name,
              component: this.constructor.name,
            })
          }
        })
    }
  }

  onRechargeSubmit = () => {
    const { selected_consumer, amount, payment_type } = this.state
    const { order_id, analytics_track_component, analytics_data } = this.props

    return this.props.rechargePayment
      .post({
        payment_id: selected_consumer,
        amount: this.toAmountInCents(amount),
        order_id: order_id,
        payment_type,
      })
      .then((_) => {
        this.props.onHide()
        if (analytics_data) {
          // Segment Tracking
          const { firstname, lastname, role, lender } = analytics_data
          analytics_track_component('Submitted Recharge Payment', {
            user: `${firstname} ${lastname}`,
            role: role.name,
            lender: lender.name,
            component: this.constructor.name,
          })
        }
      })
  }

  onSubmit = () => {
    let submitAction
    this.setState({ submitted: true })
    if (this.state.payment_type === 'recharge') {
      submitAction = this.onRechargeSubmit
    } else if (
      this.state.payment_type === 'lender' &&
      this.state.selected_card === 'new'
    ) {
      submitAction = this.onPayForAppraisalSubmit
    } else if (this.props.payment && this.props.payment.id) {
      submitAction = this.onEditSubmit
    } else {
      submitAction = this.onCreationSubmit
    }
    submitAction().then(this.props.refreshOrder)
  }

  disableSaveModal = () => {
    const {
      amount,
      up_to_amount,
      consumer,
      user_type,
      firstname,
      lastname,
      email,
      payment_type,
      selected_card,
      selected_consumer,
      submitted,
    } = this.state

    const { pay_with_new_card, total_due, use_up_to_amount } = this.props

    if (this.isInvalidAmount(payment_type, amount)) {
      return true
    }

    if (
      use_up_to_amount &&
      (this.isInvalidAmount(payment_type, up_to_amount) || (up_to_amount && up_to_amount < amount))
    ) {
      return true
    }

    if (!this.getCanChargeAboveDue(payment_type) && amount > total_due) {
      return true
    }

    if (submitted) {
      return true
    }

    if (pay_with_new_card && amount) {
      return false
    }

    if (this.hasBadPaymentType()) {
      return true
    }

    if (payment_type === 'recharge') {
      return !selected_consumer
    }

    if (payment_type === 'lender') {
      return !amount || !selected_card
    }

    if (user_type === 'manual') {
      return !firstname || !lastname || !email || !amount
    } else {
      return !consumer || !amount
    }
  }

  getConsumerSelectOptions = () => {
    const { consumers } = this.props

    const consumerSelectOptions = consumers.filter((c) => c.email && !c.hidden)

    consumerSelectOptions.push({
      full_name: '+ Create Custom Contact',
      id: 'adding_custom_contact',
    })

    return consumerSelectOptions
  }

  onAmountValueChange = (amount) => this.setState((state, props) => {
    const amountIsGreaterThanTotalDue = amount > props.total_due
    return {
      display_max_amount_warning: state.canChargeAboveDue ? false : amountIsGreaterThanTotalDue,
      amount,
    }
  })

  shouldShowCustomContactInputs = () => {
    const { consumer } = this.state
    let shouldShowCustomContactInputs = false

    if (consumer === 'adding_custom_contact') {
      shouldShowCustomContactInputs = true
    }
    return shouldShowCustomContactInputs
  }

  onPayForAppraisalEditSubmit = () => {
    const { amount, up_to_amount, payment_type, paid } = this.state
    const {
      order_id,
      currentUserId,
      is_fee_escalation_payment,
      payment: { id },
    } = this.props

    this.props.payForAppraisal
      .put({
        user_id: currentUserId,
        amount: this.toAmountInCents(amount),
        up_to_amount: this.toAmountInCents(up_to_amount),
        order_id: order_id,
        manual_payment_id: id,
        payment_type,
        paid,
        is_fee_escalation_payment,
      })
      .then((_) => {
        this.props.onHide()
      })
  }

  // Gets unique payment link for lender user and opens page
  onPayForAppraisalSubmit = () => {
    const { amount, up_to_amount, paid, user_type } = this.state
    const { order_id, currentUserId, is_fee_escalation_payment } = this.props

    return this.props.payForAppraisal
      .post({
        user_type: user_type,
        user_id: currentUserId,
        order_id: order_id,
        amount: this.toAmountInCents(amount),
        up_to_amount: this.toAmountInCents(up_to_amount),
        payment_type: 'lender',
        paid: paid,
        is_fee_escalation_payment: is_fee_escalation_payment,
      })
      .then((link) => {
        window.open(link, '_blank')
      })
  }

  onSelectPaymentType = (payment_type) => {
    if (payment_type) {
      // set payment type, then confirm that the payment amount is appropriate for the new payment type
      this.setState((state, props) => {
        return {
          payment_type: payment_type.value,
          up_to_amount: props.use_up_to_amount && payment_type.value === 'lender' ? null : state.up_to_amount,
          canChargeAboveDue: this.getCanChargeAboveDue(payment_type.value),
        }
      })
      this.onAmountValueChange(this.state.amount)
    }
  }

  render() {
    const {
      onHide,
      editing,
      consumerPayment,
      use_up_to_amount,
      corporate_cards,
      pay_with_new_card,
      paid_consumers,
      total_due,
      paymentAllowedStatus,
    } = this.props
    const {
      amount,
      up_to_amount,
      consumer,
      firstname,
      lastname,
      email,
      payment_type,
      paid,
      selected_card,
      selected_consumer,
      display_max_amount_warning,
      paymentAllowed,
      showFeeRecalculating,
    } = this.state

    if (showFeeRecalculating) {
      return (
        <Fragment>
          <ModalHeader toggle={onHide}>
            {editing ? 'Editing ' : 'Creating '} Payment
          </ModalHeader>
          <ModalBody>
            <div className="expanded-height">
              <div>
                Please wait while we finish calculating the fee. Your order will automatically refresh when complete.
              </div>
              <div className="fee-recalculating">
                <Spin size="large" />
              </div>
            </div>
          </ModalBody>
        </Fragment>
      )
    }

    if (paymentAllowedStatus === ReducerStatuses.LOADING) {
      return (
        <Fragment>
          <ModalHeader toggle={onHide}>
            {editing ? 'Editing ' : 'Creating '} Payment
          </ModalHeader>
          <ModalBody>
            <div className="expanded-height">
              <Loader/>
            </div>
          </ModalBody>
        </Fragment>
      )
    }

    if (!paymentAllowed) {
      return (
        <Fragment>
          <ModalHeader toggle={onHide}>
            {editing ? 'Editing ' : 'Creating '} Payment
          </ModalHeader>
          <ModalBody>
            <div className="expanded-height">
              <div>
                Fee is still being calculated for this order, please try again later.
              </div>
              {checkPermission('can_override_payment_allowed') && (
                <div className="override-container">
                  <Button onClick={() => this.setState({ paymentAllowed: true })}>Override and Pay</Button>
                </div>
              )}
            </div>
          </ModalBody>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {consumerPayment.isLoading && <Loader />}
        <ModalHeader toggle={onHide}>
          {editing ? 'Editing ' : 'Creating '} Payment
        </ModalHeader>
        <ModalBody>
          {this.hasBadPaymentType() && (
            <span className="text-danger">
              You are not able to save this payment. It has a payment type that
              is no longer enabled for your account. Please delete it and create
              a new payment.
            </span>
          )}
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label htmlFor="amount">Amount</Label>
                <NumberInput
                  placeholder="Amount"
                  min={0}
                  value={amount}
                  onChange={this.onAmountValueChange}
                  disabled={
                    !checkPermission('payments_can_edit_payment_amount')
                  }
                />
                {display_max_amount_warning && (
                  <span className="text-danger my-2">
                    The outstanding balance is ${total_due}.
                    You cannot charge more than the outstanding balance.
                  </span>
                )}
                {total_due > 0 && this.isInvalidAmount(payment_type, amount) && (
                  <span className="text-danger my-2">
                    Amount must be between $0.00 and $999,999.00.
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          {
            // if user selects Resend Link To Borrower, Pay w/ Corporate Card, or Create Manual Payment, show the Payment Type row
            !pay_with_new_card ? ( // o.w. leave it out (user selected Pay w/ New Card)
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label htmlFor="name">Payment Type</Label>
                    <SelectInput
                      name="payment_type"
                      placeholder={'Select the payment type'}
                      options={this.getAvailablePaymentTypes()}
                      labelKey="label"
                      valueKey="value"
                      value={payment_type}
                      clearable={false}
                      onChange={this.onSelectPaymentType}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null
          }
          {payment_type === 'manual' && (
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="name">
                    Mark this offline payment as completed
                  </Label>
                  <CheckboxInput value={paid} onChange={(paid) => this.setState({ paid })} />
                </FormGroup>
              </Col>
            </Row>
          )}
          {payment_type === 'lender' ? (
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="name">Corporate Card</Label>
                  <SelectInput
                    name="selected_card"
                    placeholder={'Select the card for payment'}
                    options={corporate_cards}
                    labelKey="label"
                    valueKey="value"
                    value={selected_card}
                    clearable={false}
                    onChange={(selected_card) =>
                      selected_card && this.setState({ selected_card: selected_card.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
          {payment_type === 'recharge' ? (
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="name">Borrower</Label>
                  <SelectInput
                    name="selected_consumer"
                    placeholder={'Select the borrower to recharge'}
                    options={paid_consumers}
                    labelKey="label"
                    valueKey="value"
                    value={selected_consumer}
                    clearable={false}
                    onChange={(selected_consumer) =>
                      selected_consumer && this.setState({ selected_consumer: selected_consumer.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
          {payment_type !== 'lender' &&
            !pay_with_new_card &&
            payment_type !== 'recharge' && (
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="consumer">Payment Contact</Label>
                  <SelectInput
                    name="consumer"
                    placeholder={
                      'Choose the consumer this payment will be sent to.'
                    }
                    options={this.getConsumerSelectOptions()}
                    labelKey="full_name"
                    valueKey="id"
                    value={consumer}
                    clearable={false}
                    onChange={(consumer) => consumer && this.setState(() => {
                      const user_type = consumer.id === 'adding_custom_contact' ? 'manual' : 'consumer'
                      return { consumer: consumer.id, user_type }
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {this.shouldShowCustomContactInputs() && payment_type !== 'lender' && (
            <Fragment>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Recipient First Name</Label>
                    <TextInput
                      placeholder="First Name"
                      value={firstname}
                      onChange={(firstname) => this.setState({ firstname })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Recipient Last Name</Label>
                    <TextInput
                      placeholder="Last Name"
                      value={lastname}
                      onChange={(lastname) => this.setState({ lastname })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Recipient Email</Label>
                    <TextInput
                      placeholder="Email"
                      value={email}
                      onChange={(email) => this.setState({ email })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Fragment>
          )}
          {use_up_to_amount && payment_type !== 'lender' ? (
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="up_to_amount">Up-to amount</Label>
                  <NumberInput
                    placeholder="Up-to amount"
                    min={0}
                    value={up_to_amount}
                    onChange={(up_to_amount) => this.setState({ up_to_amount: up_to_amount || null })}
                  />
                  {up_to_amount && up_to_amount < amount && (
                    <span className="text-danger my-2">
                      Up-to amount cannot be less than the default amount.
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onHide}>Cancel</Button>
          <Button onClick={this.onSubmit} disabled={this.disableSaveModal()}>
            Submit
          </Button>
        </ModalFooter>
      </Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  const order = selectMinimumOrder(state).order
  const paymentAllowedStatus = selectPaymentAllowedStatus(state)
  const paymentAllowed = selectPaymentAllowed(state)
  const isValutrac = order.assignmentList && order.assignmentList[0] && order.assignmentList[0].integration_type && order.assignmentList[0].integration_type === 'valutrac'
  if (isValutrac) {
    return {
      corporate_cards: [],
      is_authorize_enabled: false,
      borrower_payments_enabled: false,
      total_due: Number(order.payment_summary.total_due.toFixed(2)),
    }
  }
  let corporate_cards = []
  if (checkPermission('payment_received_can_use_lender_card') || checkPermission('payment_received_can_use_branch_card') || (props.payment && props.payment.payment_type === 'lender')) {
    corporate_cards = state.resource.cardPaymentOptions && !state.resource.cardPaymentOptions.isLoading && state.resource.cardPaymentOptions.card_list ? state.resource.cardPaymentOptions.card_list.map(card => ({ value: card.id, label: `${card.last_four}` })) : []
  }

  if (checkPermission('payment_received_can_use_new_card')) {
    corporate_cards.push({ value: 'new', label: '+ Use New Card' })
  }

  return {
    corporate_cards,
    paid_consumers: state.resource.consumerCards && state.resource.consumerCards.consumer_data && !state.resource.consumerCards.isLoading ? state.resource.consumerCards.consumer_data.map(payment => ({ value: payment.id, label: `${payment.consumer}` })) : [],
    is_authorize_enabled: state.resource.user.data.lender.is_authorize_enabled,
    borrower_payments_enabled: checkPermission('payments_can_send_payment_link'),
    total_due: Number(order.payment_summary.net_due_by_lender.toFixed(2)),
    paymentAllowedStatus,
    paymentAllowed,
  }
}

export default compose(
  connect(mapStateToProps, null),
  connectResource({
    prefetch: false,
    namespace: 'consumerPayment',
    endpoint: 'loans/payment?',
    successMessage: {
      POST: 'Your payment has been sent.',
      PUT: 'Your payment has been updated.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: true,
    list: true,
    namespace: 'cardPaymentOptions',
    endpoint: 'payment/corporate-card',
    apiVersion: 2,
  }),
  connectResource({
    prefetch: true,
    list: true,
    refresh: true,
    namespace: 'consumerCards',
    endpoint: 'payment/consumer-card/:order_id?',
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'rechargePayment',
    endpoint: 'loans/recharge-card',
    apiVersion: 2,
    successMessage: {
      POST: 'Payment has been recharged.',
    },
  }),
  connectResource({
    prefetch: false,
    namespace: 'checkPayment',
    endpoint: 'order/payment/:checkId?',
    successMessage: {
      POST: 'Your check has been sent.',
      PUT: 'Your check has been updated.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'payForAppraisal',
    endpoint: 'payment/pay-appraiser',
    apiVersion: 2,
    list: true,
    successMessage: {
      POST: 'Your payment has been created.',
      PUT: 'Your payment has been updated.',
    },
  }),
  connectResource({
    prefetch: false,
    namespace: 'paymentAllowedResource',
    refresh: true,
    endpoint: 'order/:order_id/payment-allowed',
    apiVersion: 2,
  }),
  withLDConsumer(),
)(PaymentReceivedModal)
