import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'

export const initialState = {
  verosReviews: {},
  verosReviewData: {},
}

export const fetchVerosScores = createAsyncThunk('orderReviews/fetchVerosScores', async (data, { rejectWithValue }) => {
  try {
    const { order_id } = data
    return await API('order/reviews/veros-scores', API_VERSION.TWO, SERVICE_URL).request(
      REQUEST_METHOD.GET,
      {
        order_id: order_id,
      },
      {},
    )
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchVerosScoreDetail = createAsyncThunk(
  'orderReviews/fetchVerosScoreDetail',
  async (data, { rejectWithValue }) => {
    try {
      const { score_id } = data
      return await API(`order/reviews/veros-scores/${score_id}`, API_VERSION.TWO, SERVICE_URL).request(
        REQUEST_METHOD.GET,
        {},
        {},
      )
    } catch (err) {
      return rejectWithValue(err.errors._error)
    }
  },
)

const orderReviewsSlice = createSlice({
  name: 'orderReviews',
  initialState,
  reducers: {
    resetOrderReviews: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchVerosScores.fulfilled]: (state, action) => {
      state.verosReviews = action.payload
    },
    [fetchVerosScores.rejected]: (state, action) => {
      console.log('todo fetch review result failed', action)
    },
    [fetchVerosScoreDetail.fulfilled]: (state, action) => {
      state.verosReviewData[action.meta.arg.score_id] = action.payload
    },
    [fetchVerosScoreDetail.rejected]: (state, action) => {
      console.log('todo fetch review result detail fail', action)
      state.verosReviewData[action.meta.arg.score_id] = {}
    },
  },
})

const { actions, reducer } = orderReviewsSlice
export const { resetOrderReviews } = actions

export default reducer
