import FileSaver from 'file-saver'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'

import { useApiRequest } from '../../common/hooks'
import { setShouldRefreshSingleOrder } from '../../common/layout/components/Header/actions'
import { history } from '../../init'
import { getBlob } from '../../common/utils/helpers'
import ReducerStatuses from '../../common/constants/reducer_statuses'

import {
  fetchConversations,
  fetchIndividualConversation,
  resetConversation,
  fetchIndividualConversationV2,
} from '../../store/reducers/conversation/conversationSlice'
import {
  resetOrder,
  fetchOrderTabs,
  fetchMinimumOrder,
  fetchRemainingOrder,
  fetchOrderTimeline,
  setActiveOrderTab,
  setPrimaryOrderId,
  fetchPaymentAllowed,
} from '../../store/reducers/order/orderSlice'
import {
  fetchIntegrationLogs,
} from '../../store/reducers/integrationLogs/integrationLogsSlice'
import { resetSubmissionReviews, fetchRulesets } from '@store/reducers/submissionReviews/submissionReviewsSlice'
import {
  selectOrderTabs,
  selectOrderTimeline,
  selectMinimumOrderStatus,
  selectTimelineStatus,
  selectActiveOrderTab,
  selectAllOrderData,
  selectPropertyAddress,
  selectLoanOrderData,
  selectIsUsingAmcLender,
  selectPaymentAllowedStatus,
  selectRemainingOrderStatus,
} from '../../store/reducers/order/orderSelectors'
import {
  selectAutoSendBorrowerAppraisal,
  selectAutoSendBorrowerAppraisalDelay,
  selectCurrentUser,
  selectCurrentUserId,
  selectIsAmcLender,
  selectIsReviewAppraiser,
  selectPendingApprovalStatusHelpText,
  selectReviewAppraisalHoursEnd,
  selectReviewAppraisalHoursStart,
  selectSendBorrowerAppraisal,
  selectShouldShowConsumerPaymentStyleField,
  selectStripePaymentsEnabled,
  selectUseUpToAmount,
  selectLenderData,
  selectAppraisalDeliveryStatusFieldsTogglesSettings,
  selectHasPermission,
  selectShouldShowFeeHistoryTab,
} from '../../store/reducers/user/userSelectors'
import {
  selectAMCConversation,
  selectReviewConversation,
  selectConversation,
  selectTeamConversation,
  selectConversationStatus,
} from '../../store/reducers/conversation/conversationSelectors'
import { selectShouldRefreshSingleOrder } from '../../store/reducers/resource/resourceSelectors'
import { selectIntegrationLogs } from '../../store/reducers/integrationLogs/integrationLogsSelectors'

import OrderHeader from './OrderHeader'
import OrderAlertsDismissible from './OrderAlertsDismissible'
import LoanOrderContacts from './LoanOrderContacts'
import OrderAlertsActionable from './OrderAlertsActionable'
import SingleOrderTabs from './SingleOrderTabs'
import { SmallLoader } from '../../common/layout/components/Loader'
import ModalWrapper from '../../common/modals/antd/ModalWrapper'
import PdfViewModal from '../../common/widgets/PdfViewModal'
import ReggoraProgress from '../../common/widgets/ReggoraProgress'
import VendorRatingModal from './VendorRatingModal'
import { resetOrderReviews } from '@app/store/reducers/orderReviews/orderReviewsSlice'

const DEFAULT_ACTIVE_TAB = '1'

function SingleOrder() {
  const apiRequest = useApiRequest()
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const currentUserId = useSelector(selectCurrentUserId)
  const shouldRefreshSingleOrder = useSelector(selectShouldRefreshSingleOrder)
  const is_review_appraiser = useSelector(selectIsReviewAppraiser)
  const is_amc_lender = useSelector(selectIsAmcLender)
  const use_up_to_amount = useSelector(selectUseUpToAmount)
  const auto_send_borrower_appraisal = useSelector(selectAutoSendBorrowerAppraisal)
  const auto_send_borrower_appraisal_delay = useSelector(selectAutoSendBorrowerAppraisalDelay)
  const review_appraisal_hours_start = useSelector(selectReviewAppraisalHoursStart)
  const review_appraisal_hours_end = useSelector(selectReviewAppraisalHoursEnd)
  const stripe_payments_enabled = useSelector(selectStripePaymentsEnabled)
  const send_borrower_appraisal = useSelector(selectSendBorrowerAppraisal)
  const should_show_consumer_payment_style_field = useSelector(selectShouldShowConsumerPaymentStyleField)
  const pending_approval_status_help_text = useSelector(selectPendingApprovalStatusHelpText)
  const lenderData = useSelector(selectLenderData)
  const appraisal_delivery_status_fields_toggles_settings = useSelector(
    selectAppraisalDeliveryStatusFieldsTogglesSettings,
  )
  const currentUser = useSelector(selectCurrentUser)
  const amc_conversation = useSelector(selectAMCConversation)
  const review_conversation = useSelector(selectReviewConversation)
  const conversation = useSelector((state) => selectConversation(state))
  const team_conversation = useSelector(selectTeamConversation)
  const conversationStatus = useSelector(selectConversationStatus)
  const minimumStatus = useSelector(selectMinimumOrderStatus)
  const timelineStatus = useSelector(selectTimelineStatus)
  const timeline = useSelector(selectOrderTimeline)
  const orderTabs = useSelector(selectOrderTabs)
  const integrationLogs = useSelector(selectIntegrationLogs)
  const activeOrderTab = useSelector(selectActiveOrderTab)
  const orderData = useSelector(selectAllOrderData)
  const loanData = useSelector(selectLoanOrderData)
  const propertyAddress = useSelector(selectPropertyAddress)
  const isUsingAmcLender = useSelector(selectIsUsingAmcLender)
  const shouldShowFeeHistoryTab = useSelector(selectShouldShowFeeHistoryTab)
  const paymentAllowedStatus = useSelector(selectPaymentAllowedStatus)
  const borrower_payments_enabled = useSelector((state) => selectHasPermission(state, 'payments_received_administer'))
  const loanIdForEncompass = useSelector((state) => state.app.loanIdForEncompass)
  const remainingOrderStatus = useSelector(selectRemainingOrderStatus)

  const { id: primaryOrderId } = params
  const locationFollowUpOrder = location.state?.followUpOrder
  const locationActiveOrderTab = location.state?.activeOrderTab
  const defaultActiveTab = location.state?.activeTab || DEFAULT_ACTIVE_TAB

  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  const [activeROV, setActiveROV] = useState(null)
  const [isDragOver, setIsDragOver] = useState(false)
  const [loading, setLoading] = useState(false)

  const pdfViewerHeaders = useMemo(() => {
    const token = localStorage.getItem('reggora_lender_auth_token')
    const headers = { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' }
    return headers
  }, [])

  const fetchOrderConversations = useCallback(
    (orderId) => {
      dispatch(fetchConversations(orderId)).then(
        ({ payload }) =>
          payload.conversations &&
            payload.conversations.forEach(({ id, type }) => {
              if (type === 'unified_conversation') {
                dispatch(fetchIndividualConversationV2({ id, type }))
              } else {
                dispatch(fetchIndividualConversation({ id, type }))
              }
            }
            ),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    if (lenderData) {
      dispatch(fetchRulesets({
        lenderId: lenderData.id,
      }
      ))
    }
  }, [dispatch, lenderData])

  const fetchOrder = useCallback(
    (orderId) => {
      dispatch(resetConversation())
      dispatch(fetchRemainingOrder(orderId))
      dispatch(fetchOrderTabs(orderId))
      dispatch(fetchMinimumOrder(orderId))
      dispatch(fetchPaymentAllowed(orderId))
      dispatch(fetchOrderTimeline(orderId))
      dispatch(fetchIntegrationLogs(orderId))
      fetchOrderConversations(orderId)
    },
    [dispatch, fetchOrderConversations],
  )

  useEffect(() => {
    const activeOrderTab = locationActiveOrderTab || locationFollowUpOrder || primaryOrderId
    dispatch(setActiveOrderTab(activeOrderTab))
  }, [dispatch, primaryOrderId, locationFollowUpOrder, locationActiveOrderTab])

  const refreshOrder = useCallback(() => {
    fetchOrder(activeOrderTab)
  }, [fetchOrder, activeOrderTab])

  useEffect(() => {
    return () => {
      dispatch(resetOrder())
      dispatch(resetOrderReviews())
      dispatch(resetSubmissionReviews())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (activeOrderTab) {
      fetchOrder(activeOrderTab)
    }
  }, [activeOrderTab, fetchOrder])

  useEffect(() => {
    dispatch(setPrimaryOrderId(primaryOrderId))
  }, [dispatch, primaryOrderId])

  useEffect(() => {
    if (shouldRefreshSingleOrder) {
      refreshOrder()
      dispatch(setShouldRefreshSingleOrder(false))
    }
  }, [dispatch, refreshOrder, shouldRefreshSingleOrder])

  const toggleOrder = (orderKey) => {
    dispatch(setActiveOrderTab(orderKey))
    setActiveTab(DEFAULT_ACTIVE_TAB)
  }

  const refreshIntegrationLogs = () => {
    dispatch(fetchIntegrationLogs(activeOrderTab))
  }

  const viewRov = rov => {
    const evaultRov = `${API2_URL}evault/${rov.evault_id}/${rov.id}`
    setActiveROV(evaultRov)
  }

  const downloadRov = rov => {
    getBlob(rov)
      .then(blob => {
        FileSaver.saveAs(blob, rov.document_name)
      })
  }

  const closeRov = _ => setActiveROV(null)

  const onReassignOrder = async (requested, order_id, orderInfo = {}) => {
    setLoading(true)

    const reassignResult = await apiRequest({
      endpoint: 'order/reassign',
      method: 'PUT',
      body: { order_id, requested, desktop_return: true, ...orderInfo },
      successMessage: 'Your order has been reassigned.',
    })

    setLoading(false)

    if (reassignResult.redirect) {
      history.push({ pathname: `/orders/${reassignResult.redirect}` })
    } else {
      refreshOrder()
    }
  }

  const overrideWait = async order_id => {
    setLoading(true)

    await apiRequest({
      endpoint: 'order/override',
      method: 'PUT',
      body: { order_id },
      successMessage: 'Your order has been updated.',
    })

    refreshOrder()
    setLoading(false)
  }

  const dragEnter = (e) => {
    // checks for draging over child elements
    e.preventDefault()
    if (isDragOver !== true) {
      setIsDragOver(true)
    }
  }

  const dragLeave = (e) => {
    // checks for leaving child elements before changing state
    if (isDragOver !== false) {
      setIsDragOver(false)
    }
  }

  const setLoader = (loading) => {
    setLoading(loading)
  }

  if (minimumStatus === ReducerStatuses.FAILED) {
    return <Redirect to={'/orders/'} />
  }
  if (minimumStatus !== ReducerStatuses.SUCCEEDED || loading ||
      ![ReducerStatuses.SUCCEEDED, ReducerStatuses.FAILED].includes(paymentAllowedStatus)) {
    return <SmallLoader />
  }

  return (
    <main
      className="main main--color-gray"
      onDragEnter={(e) => dragEnter(e)}
      onMouseLeave={(e) => dragLeave(e)}
    >
      {activeROV && (
        <ModalWrapper
          modal={{
            component: PdfViewModal,
            show: true,
            size: 'full',
            contentClass: 'pdf-view-modal',
            onCancel: closeRov,
          }}
          pdf={{ fileUrl: activeROV, httpHeaders: pdfViewerHeaders }}
        />
      )}
      <OrderHeader
        loanNumber={loanData.number}
        county={loanData.coverage_county_readable}
        orderTabs={orderTabs}
        activeOrderTab={activeOrderTab}
        address={propertyAddress}
        orderData={orderData}
        loanData={loanData}
        using_amc_lender={isUsingAmcLender}
        toggleOrder={toggleOrder}
        refreshOrder={refreshOrder}
        history={history}
        onReassignOrder={onReassignOrder}
        is_amc_lender={is_amc_lender}
        loanIdForEncompass={loanIdForEncompass}
      />
      <Container fluid className="bg-gray mt-5">
        <div className="dashboard-top animated fadeIn mt-2">
          {orderData.status && (
            <ReggoraProgress
              orderData={orderData}
              loanData={loanData}
              using_amc_lender={isUsingAmcLender}
              is_amc_lender={is_amc_lender}
              refreshOrder={refreshOrder}
              loading={loading}
              needs_under_review_status={
                lenderData.needs_under_review_status
              }
            />
          )}
          {orderData.alerts && (
            <OrderAlertsDismissible
              alerts={orderData.alerts}
              orderId={orderData.id}
            />
          )}
          <div className="mt-4">
            <LoanOrderContacts
              loanData={loanData}
              orderData={orderData}
              address={propertyAddress}
              appraisal_delivery_status_fields_toggles_settings={
                appraisal_delivery_status_fields_toggles_settings
              }
              setLoader={setLoader}
              refreshOrder={refreshOrder}
            />
            <Row className="mb-4 mt-4">
              <Col xs="12" md="12" className="mb-12">
                {remainingOrderStatus === ReducerStatuses.SUCCEEDED &&
                <OrderAlertsActionable
                  orderData={orderData}
                  loanData={loanData}
                  is_review_appraiser={is_review_appraiser}
                  refreshOrder={refreshOrder}
                  is_amc_lender={is_amc_lender}
                  overrideWait={overrideWait}
                  pending_approval_status_help_text={
                    pending_approval_status_help_text
                  }
                  integrationLogs={integrationLogs}
                  refreshIntegrationLogs={refreshIntegrationLogs}
                  stripe_payments_enabled={stripe_payments_enabled}
                  borrower_payments_enabled={borrower_payments_enabled}
                  currentUserID={currentUserId}
                />
                }
                <SingleOrderTabs
                  currentUserID={currentUserId}
                  orderData={orderData}
                  is_amc_lender={is_amc_lender}
                  is_review_appraiser={is_review_appraiser}
                  refreshOrder={refreshOrder}
                  activeOrderTab={activeOrderTab}
                  timeline={timeline}
                  order={orderData}
                  primaryOrderId={primaryOrderId}
                  timelineStatus={timelineStatus}
                  should_show_consumer_payment_style_field={
                    should_show_consumer_payment_style_field
                  }
                  loanData={loanData}
                  using_amc_lender={isUsingAmcLender}
                  conversation={conversation}
                  review_conversation={review_conversation}
                  is_using_amc={isUsingAmcLender}
                  use_up_to_amount={use_up_to_amount}
                  currentUser={currentUser}
                  address={propertyAddress}
                  team_conversation={team_conversation}
                  viewRov={viewRov}
                  downloadRov={downloadRov}
                  amc_conversation={amc_conversation}
                  isDragOver={isDragOver}
                  dragLeave={dragLeave}
                  conversationStatus={conversationStatus}
                  lenderData={lenderData}
                  send_borrower_appraisal={send_borrower_appraisal}
                  auto_send_borrower_appraisal={auto_send_borrower_appraisal}
                  auto_send_borrower_appraisal_delay={auto_send_borrower_appraisal_delay}
                  review_appraisal_hours_start={review_appraisal_hours_start}
                  review_appraisal_hours_end={review_appraisal_hours_end}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  shouldShowFeeHistoryTab={shouldShowFeeHistoryTab}
                  shouldShowActivityHistoryTab={!!currentUser.is_internal_reggora_user}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <VendorRatingModal />
    </main>
  )
}

export default SingleOrder
