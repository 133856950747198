import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { notification } from 'antd'
import axios from 'axios'
import FileSaver from 'file-saver'
import { DownloadOutlined } from '@ant-design/icons'
import { selectSubmissionVeros, selectVerosData } from '@store/reducers/orderReviews/orderReviewsSelectors'
import { fetchVerosScoreDetail } from '@store/reducers/orderReviews/orderReviewsSlice'

const ErrorIcon = () => <i style={{ fontSize: 30, paddingRight: '3%', color: 'red' }} className="fas fa-times-circle" />

export const showErrorToast = (message) => {
  notification.open({
    message: message || 'An error occurred. Please try again.',
    closeIcon: <></>,
    icon: <ErrorIcon />,
  })
}

const VerosScoreUnavailable = () => {
  return (
    <div className="mr-auto">
      <span className="mb-0">Veros Overall Risk Score: N/A</span>
    </div>
  )
}

const VerosScoreData = ({ verosData, downloadScoreDocument }) => {
  const overallScore = verosData?.result?.OverAllRiskScore

  return (
    <>
      <div className="mr-auto">
        <span className="mb-0">Veros Overall Risk Score: {overallScore}</span>
      </div>
      <div className="flex-grow-1 ml-3">
        <Button color="light" className="mr-2 btn-light btn-sm" type="button" onClick={() => downloadScoreDocument()}>
          <DownloadOutlined className="mr-1" /> VeroSCORE PDF
        </Button>
      </div>
    </>
  )
}

export function VerosScoreContent(props) {
  const { submission } = props

  const dispatch = useDispatch()

  const verosScore = useSelector((state) => selectSubmissionVeros(state, submission.version))
  const verosData = useSelector((state) => selectVerosData(state, verosScore?.id))

  useEffect(() => {
    if (verosScore?.id && !verosData?.id) {
      dispatch(fetchVerosScoreDetail({ score_id: verosScore?.id }))
    }
  }, [verosScore?.id, verosData?.id, dispatch])

  const downloadScoreDocument = async () => {
    try {
      const token = localStorage.getItem('reggora_lender_auth_token')

      const fileData = await axios({
        headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
        url: `${SERVICE_URL}submission-files/v1/${verosData?.score_submission_file_id}?presign=true`,
        method: 'GET',
      })

      const fileBytes = await axios({
        url: fileData?.data?.presigned_url,
        method: 'GET',
        responseType: 'blob',
      })

      FileSaver.saveAs(
        new Blob([fileBytes.data]),
        `VeroSCORE ${verosData.metadata.order_id} V${verosData.metadata.submission_version}.pdf`,
      )
    } catch (error) {
      showErrorToast('Failed to download document. Please try again later.')
      console.error('Error downloading veroSCORE document:', error)
    }
  }

  return (
    <>
      <div className="submission-tab-content veros-score__container">
        <div className="submission-tab-content__header">
          {verosData?.id === undefined ? VerosScoreUnavailable() : VerosScoreData({ verosData, downloadScoreDocument })}
        </div>
      </div>
    </>
  )
}
